.coursesPageContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbarContainer{
    background-image: url('../../assets/bg.png');
    padding: 1rem;
    font-size: 2rem;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
    color: white;
}

.contentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.galleryGrid{
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.galleryGrid img{
    width: 100%;
}