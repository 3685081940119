.pageContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.heroContainer{
    background-image: url('../../assets/bg.png');
    height: 40vh;
    min-height: 300px;
    background-position: bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.logoBanner{
    max-height: 100%;
    max-width: 100%;
    border: white 5px solid;
}

.heroHeader{
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.heroSubHeader{
    font-size: 2rem;
}

.marqueeBanner{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
}

.marqueeBanner img{
    height: 4rem;
}

.actionsList{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.actionCard{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    border: rgb(255, 17, 211) 5px solid;
    margin: 1rem;
    border-radius: 1rem;
    transition: all 0.5s ease;
}

.actionCard:hover{
    transform: translateY(-5px);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.336);
}

.actionCard img{
    width: 100%;
}

.actionCard span{
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
}

@media only screen and (max-width: 700px) {
    .heroHeader{
        font-size: 2rem;
    }
}
@media only screen and (max-width: 500px) {
    .heroHeader{
        font-size: 1.3rem;
    }
}
@media only screen and (max-width: 600px) {
    .actionsList{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}