.inquiryPageContainer{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbarContainer{
    background-image: url('../../assets/bg.png');
    padding: 1rem;
    font-size: 2rem;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
    color: white;
}

.contentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inquiryFormContainer{
    width: 70%;
    padding: 2rem 1rem;
}

.header{
    font-size: 1.5rem;
    font-weight: 500;
}

.inputContainer{
    background-color: rgb(255, 255, 255);
    color: white;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: 300px;
    flex-direction: column;
    display: flex;
    margin-top: 1rem;
}

.buttonIcons{
    margin-right: 0.5rem;
}

.regNumCheck{
    background-color: #8216ee;
    color: white;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
}

.validCheckBtn{
    background-color: #2ecc71;
}

.invalidCheckBtn{
    background-color: #e74c3c;
}

.inputContainerDropdown{
    background-color: rgb(255, 255, 255);
    color: white;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: 300px;
    flex-direction: column;
    display: flex;
    margin-top: 1rem;
}

.inputContainer span, .inputContainerDropdown span {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
    color: black;
}

.inputContainer input{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.1rem;
}

.submitBtn{
    background-color: #8216ee;
    color: white;
    border: none;
    outline: none;
    border-bottom: #510e94 6px solid;
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.submitBtn:hover{
    transform: translateY(-2px);
}

.submitBtn:active {
    transition: all 0.1s ease;
    transform: translateY(4px);
}

@media only screen and (max-width: 500px) {
    .navbarContainer{
        font-size: 1.2rem;
    }

    .inquiryFormContainer{
        width: 100%;
        padding: 1rem;
    }

    .inputContainer, .inputContainerDropdown{
        width: 100%;
    }
}